import axios, { AxiosInstance } from 'axios'
import { API_URL, AUTH_COOKIE_NAME } from '../config'
import { getCookie } from '../utils'

let CLIENT: AxiosInstance

export const getClient = () => {
  if (!CLIENT) {
    const authorizationToken = getCookie(AUTH_COOKIE_NAME)

    CLIENT = axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: authorizationToken && `Bearer ${authorizationToken}`
      }
    })
  }

  return CLIENT
}
