import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/react'

type Props = {
  label: string
  to: string
}

export const SidebarItem = ({ label, to }: Props) => {
  return (
    <Box>
      <Link as={RouterLink} to={to} fontSize={16} paddingY="6px" display="block">
        {label}
      </Link>
    </Box>
  )
}
