import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { AppRouter } from './navigation'
import { theme } from './theme'

function App () {
  return (
    <ChakraProvider theme={theme}>
      <AppRouter />
    </ChakraProvider>
  )
}

export default App
