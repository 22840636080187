import { getClient } from './client'
import { EcpAssociate } from '../models'

export const findAssociateByRegistrationNumber = (registrationNumber: string) => {
  return getClient().get<EcpAssociate>('/ecp/find-associate', { params: { registrationNumber } })
}

export const syncAssociate = (registrationNumber: string) => {
  return getClient().post<EcpAssociate>('/ecp/sync-associate', { registrationNumber })
}
