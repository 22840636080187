import { getClient } from './client'

export const getOrganizationConfigByOrganizationId = (organizationId: string) => {
  return getClient().get('/organizations/config', { params: { organizationId } })
}

export const getOrganizations = () => {
  return getClient().get('/organizations')
}

export const updateOrCreateOrganizationConfig = (data: any) => {
  return getClient().post('/organizations/config', data)
}
