import React, { useState, useCallback } from 'react'
import { Box, Button, FormControl, FormLabel, Heading, Input, useToast } from '@chakra-ui/react'
import { authToken } from '../../api/auth'
import { addDays, setCookie } from '../../utils'
import { AUTH_COOKIE_NAME } from '../../config'

export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const toast = useToast()

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault()
      setLoading(true)

      try {
        const {
          data: { accessToken }
        } = await authToken(email, password)
        const tokenExpirationDate = addDays(new Date(), 1)
        setCookie(AUTH_COOKIE_NAME, accessToken, { expires: tokenExpirationDate })
        window.location.href = '/'
      } catch (error) {
        console.error(error)
        toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
        setLoading(false)
      }
    },
    [loading, email, password]
  )

  const renderForm = () => {
    return (
      <form onSubmit={handleLogin}>
        <FormControl marginBottom={4}>
          <FormLabel>Endereço de e-mail</FormLabel>
          <Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
        </FormControl>
        <FormControl marginBottom={6}>
          <FormLabel>Senha</FormLabel>
          <Input type="password" value={password} onChange={(event) => setPassword(event.target.value)} required />
        </FormControl>
        <Button type="submit" isLoading={loading} width="100%">
          Entrar
        </Button>
      </form>
    )
  }

  return (
    <Box marginY={20} marginX="auto" width="100%" maxWidth="md">
      <Box width="100%">
        <Heading as="h2" marginBottom={6} textAlign="center">
          Login
        </Heading>
        {renderForm()}
      </Box>
    </Box>
  )
}
