import React, { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Heading, Link } from '@chakra-ui/layout'

import { logout } from '../../utils'

export const Header = () => {
  const handleLogout = useCallback(() => {
    logout()
  }, [])

  return (
    <Box backgroundColor="primary.600" paddingY={4} paddingX={8} display="flex" justifyContent="space-between">
      <Heading as="h2" color="white" fontSize={18}>
        BlueOne Admin
      </Heading>
      <Box>
        <Link as={RouterLink} to="#" onClick={handleLogout} color="white" fontWeight="500" fontSize={16}>
          Sair
        </Link>
      </Box>
    </Box>
  )
}
