import { Cookies } from 'react-cookie'

export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
}

const cookiesInstance = new Cookies()

export const getCookie = (key: string) => {
  return cookiesInstance.get(key)
}

export const setCookie = (key: string, value: string, options?: CookieSetOptions) => {
  cookiesInstance.set(key, value, options)
}

export const removeAllCookies = () => {
  const cookies = cookiesInstance.getAll()
  Object.keys(cookies).map((cookie: string) => cookiesInstance.remove(cookie))
}
