import { AUTH_COOKIE_NAME } from '../config'
import { getCookie, removeAllCookies } from './cookie'

export const isAuthenticated = (): boolean => {
  const authCookie = getCookie(AUTH_COOKIE_NAME)
  return !!authCookie
}

export const logout = (): void => {
  removeAllCookies()
  window.location.href = '/login'
}
