import React, { useCallback, useState } from 'react'
import { useToast, FormControl, FormLabel, Input, Button } from '@chakra-ui/react'
import { Page } from '../../../components/Page'
import { UserResetPasswordForm } from '../../../models'
import { resetPassword } from '../../../api'

export const UsersResetPasswordPage = () => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState<UserResetPasswordForm>({
    email: '',
    password: '',
    passwordConfirmation: ''
  })

  const handleReset = useCallback(
    async (event) => {
      event.preventDefault()
      setLoading(true)

      try {
        await resetPassword(formValues)
        setFormValues({
          email: '',
          password: '',
          passwordConfirmation: ''
        })
        toast({ title: 'Show!', description: 'Senha alterada com sucesso', status: 'success' })
        setLoading(false)
      } catch (error) {
        console.error(error)
        toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
        setLoading(false)
      }
    },
    [formValues, loading]
  )

  return (
    <Page title="Alterar senha">
      <form onSubmit={handleReset}>
        <FormControl id="email">
          <FormLabel>E-mail</FormLabel>
          <Input
            type="email"
            placeholder="Informe o e-mail do usuário"
            value={formValues.email}
            onChange={(event) => setFormValues({ ...formValues, email: event.target.value })}
          />
        </FormControl>

        <FormControl marginTop={2} id="password">
          <FormLabel>Senha</FormLabel>
          <Input
            type="password"
            placeholder="Informe a nova senha do usuário"
            value={formValues.password}
            onChange={(event) => setFormValues({ ...formValues, password: event.target.value })}
          />
        </FormControl>

        <FormControl marginTop={2} id="passwordConfirmation">
          <FormLabel>Confirme a nova senha</FormLabel>
          <Input
            type="password"
            placeholder="Informe novamente a senha do usuário"
            value={formValues.passwordConfirmation}
            onChange={(event) => setFormValues({ ...formValues, passwordConfirmation: event.target.value })}
          />
        </FormControl>

        <Button type="submit" marginTop={2} isLoading={loading}>
          Alterar
        </Button>
      </form>
    </Page>
  )
}
