import React, { useState, useCallback } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react'
import { Page } from '../../../components/Page'
import { findAssociateByRegistrationNumber } from '../../../api'
import { EcpAssociate } from '../../../models'

export const EcpFindAssociatePage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [registrationNumber, setRegistrationNumber] = useState<string>('')
  const [associate, setAssociate] = useState<EcpAssociate | null>(null)
  const toast = useToast()

  const handleSearch = useCallback(
    async (event) => {
      event.preventDefault()
      setLoading(true)
      setAssociate(null)

      try {
        const { data } = await findAssociateByRegistrationNumber(registrationNumber)
        setLoading(false)
        setAssociate(data)
      } catch (error) {
        console.error(error)
        toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
        setLoading(false)
      }
    },
    [registrationNumber, loading]
  )

  function renderAssociateDataField (label: string, value: string) {
    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Input value={value} readOnly={true} />
      </FormControl>
    )
  }

  function renderAssociateData () {
    return (
      associate && (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gridGap={4}>
          {Object.keys(associate.contactData).map((key: string) =>
            renderAssociateDataField(key, (associate.contactData as any)[key])
          )}
        </Box>
      )
    )
  }

  function renderAssociateAddressess () {
    return (
      <Table size="sm">
        <Thead>
          <Th>CEP</Th>
          <Th>Rua</Th>
          <Th>Número</Th>
          <Th>Bairro</Th>
          <Th>Complemento</Th>
          <Th>Cidade</Th>
          <Th>UF</Th>
          <Th>Tipo</Th>
          <Th>Principal</Th>
        </Thead>
        <Tbody>
          {associate?.addressesData.map((address) => (
            <Tr key={address.address_street}>
              <Td>{address.zipcode}</Td>
              <Td>{address.address_street}</Td>
              <Td>{address.address_number}</Td>
              <Td>{address.neighborhood}</Td>
              <Td>{address.observations}</Td>
              <Td>{address.city}</Td>
              <Td>{address.state}</Td>
              <Td>{address.type}</Td>
              <Td>{address.is_main ? 'SIM' : 'NÃO'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    )
  }

  function renderAssociatePhones () {
    return (
      <Table size="sm">
        <Thead>
          <Th>Telefone</Th>
          <Th>Tipo</Th>
          <Th>WhatsApp</Th>
          <Th>Principal</Th>
        </Thead>
        <Tbody>
          {associate?.phonesData.map((phone) => (
            <Tr key={phone.phone}>
              <Td>{phone.phone}</Td>
              <Td>{phone.type}</Td>
              <Td>{phone.has_whatsapp ? 'SIM' : 'NÃO'}</Td>
              <Td>{phone.is_main ? 'SIM' : 'NÃO'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    )
  }

  function renderAssociateEmails () {
    return (
      <Table size="sm">
        <Thead>
          <Th>Email</Th>
          <Th>Verificado</Th>
          <Th>Principal</Th>
        </Thead>
        <Tbody>
          {associate?.emailsData.map((email) => (
            <Tr key={email.email}>
              <Td>{email.email}</Td>
              <Td>{email.is_verified ? 'SIM' : 'NÃO'}</Td>
              <Td>{email.is_main ? 'SIM' : 'NÃO'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    )
  }

  return (
    <Page title="Buscar associado">
      <form onSubmit={handleSearch}>
        <FormControl id="registrationNumber">
          <FormLabel>Matrícula</FormLabel>
          <Input
            type="text"
            placeholder="Informe a matrícula do associado"
            value={registrationNumber}
            onChange={(event) => setRegistrationNumber(event.target.value)}
          />
        </FormControl>
        <Button type="submit" marginTop={2} isLoading={loading}>
          Buscar
        </Button>
      </form>
      {associate && !loading && (
        <Box borderTopWidth={1} borderTopColor="gray.200" marginTop={8} paddingTop={8}>
          <Tabs>
            <TabList>
              <Tab>Associado</Tab>
              <Tab>Endereços</Tab>
              <Tab>Telefones</Tab>
              <Tab>Emails</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>{renderAssociateData()}</TabPanel>
              <TabPanel>{renderAssociateAddressess()}</TabPanel>
              <TabPanel>{renderAssociatePhones()}</TabPanel>
              <TabPanel>{renderAssociateEmails()}</TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Page>
  )
}
