import React from 'react'
import { Box } from '@chakra-ui/layout'
import { SidebarTitle } from '../SidebarTitle'
import { SidebarItem } from '../SidebarItem'
import { MENU_ITEMS } from '../../navigation/nav'

export const Sidebar = () => {
  return (
    <Box width="1xs" paddingX={8} borderRightColor="blackAlpha.200" borderRightWidth={2} minHeight="85vh">
      {MENU_ITEMS.map((group) => (
        <Box key={group.title} marginBottom={8}>
          {group.title && <SidebarTitle title={group.title} />}
          {group.items.map((item) => (
            <SidebarItem key={item.path} label={item.label} to={item.path} />
          ))}
        </Box>
      ))}
    </Box>
  )
}
