import React, { useEffect, useState } from 'react'
import { useToast, FormControl, FormLabel, Button, Select } from '@chakra-ui/react'
import { Page } from '../../../components/Page'
import { OrganizationConfigForm } from '../../../models'
import { getOrganizationConfigByOrganizationId, getOrganizations, updateOrCreateOrganizationConfig } from '../../../api'

export const OrganizationConfigPage = () => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState<any[]>([])
  const [formValues, setFormValues] = useState<OrganizationConfigForm>({
    organizationId: '',
    tabulationMode: 'unique'
  })

  const handleUpdateOrCreate = async (event: any) => {
    try {
      event.preventDefault()
      setLoading(true)
      await updateOrCreateOrganizationConfig(formValues)
      toast({ title: 'Sucesso', description: 'Configurações alteradas com sucesso', status: 'success' })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
    }
  }

  const handleGetOrganizationConfig = async () => {
    try {
      setLoading(true)
      const response = await getOrganizationConfigByOrganizationId(formValues.organizationId)

      if (response.data) {
        setFormValues({
          ...formValues,
          tabulationMode: response.data.tabulationMode
        })
      } else {
        setFormValues({
          ...formValues,
          tabulationMode: 'unique'
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (formValues.organizationId) {
        toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
      }
      setFormValues({
        organizationId: '',
        tabulationMode: 'unique'
      })
    }
  }

  const handleGetOrganizations = async () => {
    try {
      setLoading(true)
      const response = await getOrganizations()

      if (response.data) {
        setOrganizations(response.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
    }
  }

  useEffect(() => {
    handleGetOrganizationConfig()
  }, [formValues.organizationId])

  useEffect(() => {
    handleGetOrganizations()
  }, [])

  return (
    <Page title="Alterar senha">
      <form onSubmit={handleUpdateOrCreate}>
        <FormControl id="organization">
          <FormLabel>Organização</FormLabel>
          <Select placeholder="Selecione um item" value={formValues.organizationId} onChange={(event) => setFormValues({ ...formValues, organizationId: event.target.value })}>
            {organizations.map(el => (<option key={el.id} value={el.id}>{el.name}</option>))}
          </Select>
        </FormControl>

        <FormControl marginTop={2} id="tabulationMode">
          <FormLabel>Modo de tabulação</FormLabel>
          <Select
            placeholder="Selecione um item"
            value={formValues.tabulationMode}
            onChange={(event) => setFormValues({ ...formValues, tabulationMode: event.target.value as any })}
          >
            <option value='unique'>Unico</option>
            <option value='multiple'>Múltiplo</option>
          </Select>
        </FormControl>

        <Button type="submit" marginTop={2} isLoading={loading}>
          Salvar
        </Button>
      </form>
    </Page>
  )
}
