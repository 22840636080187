export interface MenuItem {
  label: string
  path: string
}

export interface MenuGroup {
  title?: string
  items: MenuItem[]
}

export const MENU_ITEMS: MenuGroup[] = [
  {
    items: [{ label: 'Dashboard', path: '/' }]
  },
  {
    title: 'ECP',
    items: [
      { label: 'Buscar associado', path: '/ecp/find-associate' },
      { label: 'Sincronizar associado', path: '/ecp/sync-associate' }
      // { label: 'Atualizar foto do associado', path: '/ecp/sync-photo' }
    ]
  },
  {
    title: 'Usuários',
    items: [
      { label: 'Alterar senha', path: '/users/reset-password' }
    ]
  },
  {
    title: 'Organizações',
    items: [
      { label: 'Configurações', path: '/organizations/config' }
    ]
  }
  // {
  //   title: 'Usuários',
  //   items: [
  //     { label: 'Listar usuários', path: '/users' },
  //     { label: 'Novo usuário', path: '/users/create' }
  //   ]
  // }
]
