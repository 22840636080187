import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

type Props = {
  children?: React.ReactNode
  title?: string
}

export const Page = (props: Props) => {
  return (
    <Box>
      <Header />
      <Box display="flex" alignItems="flex-start" paddingY={10}>
        <Sidebar />
        <Box flex="1" paddingX={8}>
          {props.title && (
            <Heading as="h2" fontSize={24} fontWeight="600" marginBottom={12}>
              {props.title}
            </Heading>
          )}
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}
