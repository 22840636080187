import React from 'react'
import { Heading } from '@chakra-ui/layout'

type Props = {
  title: string
}

export const SidebarTitle = ({ title }: Props) => {
  return (
    <Heading as="h4" fontSize={24} fontWeight="500" marginBottom={3} color="black">
      {title}
    </Heading>
  )
}
