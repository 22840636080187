import React, { useCallback, useState } from 'react'
import { Button, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import { Page } from '../../../components/Page'
import { syncAssociate } from '../../../api/ecp'

export const EcpSyncAssociatePage = () => {
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(false)
  const [registrationNumber, setRegistrationNumber] = useState<string>('')

  const handleSync = useCallback(
    async (event) => {
      event.preventDefault()
      setLoading(true)

      try {
        await syncAssociate(registrationNumber)
        setRegistrationNumber('')
        setLoading(false)
        toast({ title: 'Tudo certo', description: 'Pedido de sincronização enviado', status: 'success' })
      } catch (error) {
        console.error(error)
        toast({ title: 'Erro', description: JSON.stringify(error?.response?.data?.message || {}), status: 'error' })
        setLoading(false)
      }
    },
    [registrationNumber, loading]
  )

  const renderForm = () => {
    return (
      <form onSubmit={handleSync}>
        <FormControl id="registrationNumber">
          <FormLabel>Matrícula</FormLabel>
          <Input
            type="text"
            placeholder="Informe o código de matrícula do associado"
            value={registrationNumber}
            onChange={(event) => setRegistrationNumber(event.target.value)}
          />
        </FormControl>
        <Button type="submit" marginTop={2} isLoading={loading}>
          Sincronizar
        </Button>
      </form>
    )
  }

  return <Page title="Sincronizar associado">{renderForm()}</Page>
}
