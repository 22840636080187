import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../components/PrivateRoute'
import { LoginPage } from '../pages/Login'
import { DashboardPage } from '../pages/Dashboard'
import { EcpSyncAssociatePage } from '../pages/ECP/SyncAssociate'
import { EcpFindAssociatePage } from '../pages/ECP/FindAssociate'
import { UsersResetPasswordPage } from '../pages/Users/ResetPassword'
import { OrganizationConfigPage } from '../pages/Organizations/Config'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/" exact component={DashboardPage} />
        <PrivateRoute path="/ecp/find-associate" component={EcpFindAssociatePage} />
        <PrivateRoute path="/ecp/sync-associate" component={EcpSyncAssociatePage} />
        <PrivateRoute path="/users/reset-password" component={UsersResetPasswordPage} />
        <PrivateRoute path="/organizations/config" component={OrganizationConfigPage} />
      </Switch>
    </BrowserRouter>
  )
}
